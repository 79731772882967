import React from 'react'
import { Link } from 'gatsby'

import './style.css'

interface Prop {
}

const Header: React.FC<Props> = () => (
  <header className="c-header">

    <div className="c-header__inner">
      {/* Logo */}
      <h1 className="c-header__inner__logo">
        <Link to="/" className="blink">
          RPEDRONI
        </Link>
      </h1>

      {/* Menu */}
      <menu className="c-header__inner__menu">
        {/* <Link to="/projects">Projetos</Link> */}
        <Link to="/disciplinas/circuitos-digitais">CIRDIG</Link>
        <Link to="/disciplinas/logica-reconfiguravel">LOGREC</Link>
        <Link to="/disciplinas/logica-reconfiguravel">ELEDIG</Link>
      </menu>

    </div>

  </header>
)

export default Header
