import React from 'react'
import PropTypes from 'prop-types'

import Header from '../header'
import Footer from '../Footer'

import './style.css'

const Layout = ({ children }) => {
  return (
    <>
      <div className="c-layout">
        <Header />
        <main className="c-layout__content">
          {children}
        </main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
