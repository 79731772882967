import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="c-Footer">
      Ricardo Pedroni © {year}
    </footer>
  )
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
